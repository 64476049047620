import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
// import config from 'src/config';
import {
  faTable,
  faCog,
  // faCreditCard,
  faHistory,
  faThLarge,
  faUserPlus,
  faFileInvoice,
  faBriefcase,
  faClock,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: faThLarge,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },
  
  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    permissionType: 'canViewUsers',
    icon: faUserPlus,
  },
  
  {
    path: '/audit-logs',
    icon: faHistory,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
    permissionType: 'isPartner',
  },
  
  {
    path: '/settings',
    icon: faCog,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
    permissionType: 'canChangeWorkspaceSettings',
  },

  {
    path: '/max-bid-table',
    icon: faTable,
    // TODO i18n
    label: 'Max Bids',
    permissionRequired: null,
    permissionType: 'isPartner',
  },

  {
    path: '/program-logs',
    icon: faFileInvoice,
    // TODO i18n
    label: 'Program Logs',
    permissionRequired: null,
    permissionType: 'isPartner',
  },

  {
    path: '/leads',
    icon: faBriefcase,
    label: 'Leads',
    permissionRequired: null,
    permissionType: 'isAdmin',
  },

  {
    path: '/plan',
    permissionRequired: permissions.planRead,
    permissionType: 'canViewPayments',
    icon: faCreditCard,
    label: i18n('plan.menu'),
  },

  // {
  //   path: '/time-settings',
  //   icon: faClock,
  //   label: 'Time Settings',
  //   permissionRequired: null,
  //   permissionType: 'isAdmin',
  // },

  // {
  //   path: '/user-data',
  //   permissionRequired: permissions.userDataRead,
  //   icon: faChevronRight,
  //   label: i18n('entities.userData.menu'),
  // },

  // {
  //   path: '/daily-report',
  //   permissionRequired: permissions.dailyReportRead,
  //   icon: faChevronRight,
  //   label: i18n('entities.dailyReport.menu'),
  // },

  // {
  //   path: '/monthly-reports',
  //   permissionRequired: permissions.monthlyReportsRead,
  //   icon: faChevronRight,
  //   label: i18n('entities.monthlyReports.menu'),
  // },  

  // {
  //   path: '/ads',
  //   permissionRequired: permissions.adsRead,
  //   icon: faChevronRight,
  //   label: i18n('entities.ads.menu'),
  // }, 
].filter(Boolean);
